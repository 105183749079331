export default [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "lv",
        "type": "uint256"
      }
    ],
    "name": "getExp",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "pure",
    "type": "function"
  }
] as const;