export default {
  "0x6697b232": "AccessControlBadConfirmation",
  "0xe2517d3f": "AccessControlUnauthorizedAccount",
  "0x9996b315": "AddressEmptyCode",
  "0x8dd2f3ad": "AtLeastOneOperatorRole",
  "0xf1561fab": "BoardChanged",
  "0xed56dcf7": "ContractDisallowed",
  "0x4c9c8ce3": "ERC1967InvalidImplementation",
  "0xb398979f": "ERC1967NonPayable",
  "0x1425ea42": "FailedInnerCall",
  "0x1a288b04": "GameNotInProgress",
  "0xbcc5fd2e": "HeroLocked",
  "0xf92ee8a9": "InvalidInitialization",
  "0x9cb3af67": "InvalidLevel",
  "0x6a16d40f": "InvalidOwner",
  "0x09bde339": "InvalidProof",
  "0xb654334c": "NonceChanged",
  "0xea8a56bc": "NoRewardToClaim",
  "0xacb4414c": "NotEnoughFee",
  "0xd7e6bcf8": "NotInitializing",
  "0x3ee5aeb5": "ReentrancyGuardReentrantCall",
  "0xe2caddf7": "TooManyHeroes",
  "0xb2a1cb70": "TooManyProof",
  "0x16f52576": "TooManySteps",
  "0xf158568e": "TransferGPFailed",
  "0x0100f508": "TransferNativeTokenFailed",
  "0xe07c8dba": "UUPSUnauthorizedCallContext",
  "0xaa1d49a4": "UUPSUnsupportedProxiableUUID",
  "0x893cc576": "AlreadyMinted",
  "0x404c03b6": "AlreadyUnboxed",
  "0x1984e143": "AtLeastMintOne",
  "0x65a5a552": "EditionMismatched",
  "0xa9b62e05": "EOAOnly",
  "0x5adc2b78": "EquipmentLocked",
  "0x59171fc1": "ERC721EnumerableForbiddenBatchMint",
  "0x64283d7b": "ERC721IncorrectOwner",
  "0x177e802f": "ERC721InsufficientApproval",
  "0xa9fbf51f": "ERC721InvalidApprover",
  "0x5b08ba18": "ERC721InvalidOperator",
  "0x89c62b64": "ERC721InvalidOwner",
  "0x64a0ae92": "ERC721InvalidReceiver",
  "0x73c6ac6e": "ERC721InvalidSender",
  "0x7e273289": "ERC721NonexistentToken",
  "0xa57d13dc": "ERC721OutOfBoundsIndex",
  "0x3e5698b6": "HeroNotRevealed",
  "0x7997fa76": "InsufficientDonation",
  "0x9b6366bd": "InvalidEquipmentKind",
  "0x01672028": "NotEnoughFee",
  "0x5dfbf947": "NotEnoughVit",
  "0xc4327faa": "ReachedMaxLevel",
  "0xe89cfe10": "StillEquiped",
  "0xa2269912": "WithdrawFailed",
  "0x96c35b57": "EquipmentNotRevealed"
} as const;