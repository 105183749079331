// import { erc20ABI } from 'wagmi';
import API from 'types/crypto-rumble-periphery/abis/API';
import CREvent from 'types/crypto-rumble-periphery/abis/CREvent';
import CheckIn from 'types/crypto-rumble-periphery/abis/CheckIn';
import CryptoRumble from 'types/crypto-rumble-periphery/abis/CryptoRumble';
import Equipment from 'types/crypto-rumble-periphery/abis/Equipment';
import EquipmentEdition1 from 'types/crypto-rumble-periphery/abis/EquipmentEdition1';
import Hero from 'types/crypto-rumble-periphery/abis/Hero';
import HeroEdition1 from 'types/crypto-rumble-periphery/abis/HeroEdition1';
import HeroEdition2 from 'types/crypto-rumble-periphery/abis/HeroEdition2';
import IRewarder from 'types/crypto-rumble-periphery/abis/IRewarder';
import Level from 'types/crypto-rumble-periphery/abis/Level';
import LevelUpHelper from 'types/crypto-rumble-periphery/abis/LevelUpHelper';
import Multicall3 from 'types/crypto-rumble-periphery/abis/Multicall3';
import Restaking from 'types/crypto-rumble-periphery/abis/Restaking';
import XmasEvent from 'types/crypto-rumble-periphery/abis/XmasEvent';
import errors from 'types/crypto-rumble-periphery/abis/errors';
import HeroPresale from './crypto-rumble-periphery/abis/HeroPresale';

export const ABI_CONST_MOUNTED = {
  // GP: erc20ABI,
  API,
  CREvent,
  CheckIn,
  CryptoRumble,
  Equipment,
  EquipmentEdition1,
  Hero,
  HeroEdition1,
  HeroEdition2,
  IRewarder,
  Level,
  LevelUpHelper,
  Multicall3,
  Restaking,
  XmasEvent,
  errors,
  HeroPresale,
};
