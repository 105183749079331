import { wagmiConfig } from 'constants/wagmi';
import { atom, selector } from 'recoil';
import crMainNet from 'types/crypto-rumble-periphery/contract-mainnet';

export const stateAppChain = atom({
  key: 'stateAppChain',
  default: wagmiConfig.chains[0] as (typeof wagmiConfig.chains)[number],
});

export const stateAppChainInfo = selector({
  key: 'stateAppChainInfo',
  get({ get, getCallback }) {
    const chain = get(stateAppChain);
    return crMainNet[chain.id];
  },
});
