export default [
  {
    "inputs": [],
    "name": "AccessControlBadConfirmation",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "bytes32",
        "name": "neededRole",
        "type": "bytes32"
      }
    ],
    "name": "AccessControlUnauthorizedAccount",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "target",
        "type": "address"
      }
    ],
    "name": "AddressEmptyCode",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "ERC1967InvalidImplementation",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ERC1967NonPayable",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "FailedInnerCall",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidInitialization",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotInitializing",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "UUPSUnauthorizedCallContext",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "slot",
        "type": "bytes32"
      }
    ],
    "name": "UUPSUnsupportedProxiableUUID",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "uint64",
        "name": "version",
        "type": "uint64"
      }
    ],
    "name": "Initialized",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "previousAdminRole",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "newAdminRole",
        "type": "bytes32"
      }
    ],
    "name": "RoleAdminChanged",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      }
    ],
    "name": "RoleGranted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "sender",
        "type": "address"
      }
    ],
    "name": "RoleRevoked",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "implementation",
        "type": "address"
      }
    ],
    "name": "Upgraded",
    "type": "event"
  },
  {
    "inputs": [],
    "name": "DEFAULT_ADMIN_ROLE",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "UPGRADE_INTERFACE_VERSION",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "checkIn",
    "outputs": [
      {
        "internalType": "contract CheckIn",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "cryptRumble",
    "outputs": [
      {
        "internalType": "contract CryptoRumble",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "equipment",
    "outputs": [
      {
        "internalType": "contract Equipment",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "equipmentEdition",
    "outputs": [
      {
        "internalType": "contract EquipmentEdition",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "player",
        "type": "address"
      }
    ],
    "name": "getAvailableLevel",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint32[5]",
                    "name": "resistanceRatio",
                    "type": "uint32[5]"
                  },
                  {
                    "internalType": "uint32",
                    "name": "moves",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "hp",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "class",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32[5]",
                    "name": "resistanceAbsolute",
                    "type": "uint32[5]"
                  },
                  {
                    "internalType": "uint32",
                    "name": "vitCost",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "invulnerable",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint256",
                    "name": "board",
                    "type": "uint256"
                  },
                  {
                    "internalType": "address",
                    "name": "reward",
                    "type": "address"
                  }
                ],
                "internalType": "struct ILevel.Info",
                "name": "info",
                "type": "tuple"
              },
              {
                "internalType": "uint256",
                "name": "levelId",
                "type": "uint256"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "exp",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "gp",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "native",
                    "type": "uint256"
                  },
                  {
                    "internalType": "bool",
                    "name": "heroReward",
                    "type": "bool"
                  },
                  {
                    "internalType": "bool",
                    "name": "equipmentReward",
                    "type": "bool"
                  },
                  {
                    "internalType": "bool",
                    "name": "nftReward",
                    "type": "bool"
                  }
                ],
                "internalType": "struct API.RewardInfo",
                "name": "reward",
                "type": "tuple"
              }
            ],
            "internalType": "struct API.LevelInfo[]",
            "name": "levels",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "components": [
                      {
                        "internalType": "uint32[5]",
                        "name": "resistanceRatio",
                        "type": "uint32[5]"
                      },
                      {
                        "internalType": "uint32",
                        "name": "moves",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "hp",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "class",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32[5]",
                        "name": "resistanceAbsolute",
                        "type": "uint32[5]"
                      },
                      {
                        "internalType": "uint32",
                        "name": "vitCost",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "invulnerable",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint256",
                        "name": "board",
                        "type": "uint256"
                      },
                      {
                        "internalType": "address",
                        "name": "reward",
                        "type": "address"
                      }
                    ],
                    "internalType": "struct ILevel.Info",
                    "name": "info",
                    "type": "tuple"
                  },
                  {
                    "internalType": "uint32",
                    "name": "timeStart",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "timeLength",
                    "type": "uint32"
                  }
                ],
                "internalType": "struct ILevel.Special",
                "name": "info",
                "type": "tuple"
              },
              {
                "internalType": "uint256",
                "name": "levelId",
                "type": "uint256"
              },
              {
                "components": [
                  {
                    "internalType": "uint256",
                    "name": "exp",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "gp",
                    "type": "uint256"
                  },
                  {
                    "internalType": "uint256",
                    "name": "native",
                    "type": "uint256"
                  },
                  {
                    "internalType": "bool",
                    "name": "heroReward",
                    "type": "bool"
                  },
                  {
                    "internalType": "bool",
                    "name": "equipmentReward",
                    "type": "bool"
                  },
                  {
                    "internalType": "bool",
                    "name": "nftReward",
                    "type": "bool"
                  }
                ],
                "internalType": "struct API.RewardInfo",
                "name": "reward",
                "type": "tuple"
              }
            ],
            "internalType": "struct API.SpecialLevelInfo[]",
            "name": "specialLevels",
            "type": "tuple[]"
          },
          {
            "internalType": "uint256",
            "name": "progress",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.Levels",
        "name": "lvs",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "timestamp",
        "type": "uint256"
      }
    ],
    "name": "getCheckInfo",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "dailyWinReward",
            "type": "uint256"
          },
          {
            "internalType": "bool",
            "name": "dailyWinClaimed",
            "type": "bool"
          },
          {
            "internalType": "bool",
            "name": "dailyWinCanClaim",
            "type": "bool"
          },
          {
            "internalType": "uint256[]",
            "name": "checkInRewards",
            "type": "uint256[]"
          },
          {
            "internalType": "bool[7]",
            "name": "checkedIn",
            "type": "bool[7]"
          },
          {
            "internalType": "uint256",
            "name": "nextRewardIndex",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.CheckDTO",
        "name": "dto",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "player",
        "type": "address"
      }
    ],
    "name": "getCurrentGame",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "address",
                "name": "player",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "gameId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "levelId",
                "type": "uint256"
              },
              {
                "internalType": "uint256[3]",
                "name": "heroId",
                "type": "uint256[3]"
              },
              {
                "internalType": "uint128",
                "name": "timeStart",
                "type": "uint128"
              },
              {
                "internalType": "uint128",
                "name": "timeEnd",
                "type": "uint128"
              },
              {
                "internalType": "uint256",
                "name": "nonce",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "board",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "damageMade",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "moveMade",
                "type": "uint256"
              },
              {
                "internalType": "bool",
                "name": "specialLevel",
                "type": "bool"
              }
            ],
            "internalType": "struct ICryptoRumble.Game",
            "name": "game",
            "type": "tuple"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint128",
                    "name": "attack",
                    "type": "uint128"
                  },
                  {
                    "internalType": "uint32",
                    "name": "quality",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "class",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "lv",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "exp",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "locked",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "lvCap",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "edition",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint128",
                    "name": "atkGroth",
                    "type": "uint128"
                  },
                  {
                    "internalType": "uint128[3]",
                    "name": "equiped",
                    "type": "uint128[3]"
                  },
                  {
                    "internalType": "bool",
                    "name": "revealed",
                    "type": "bool"
                  }
                ],
                "internalType": "struct IHero.Stats",
                "name": "stats",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "components": [
                      {
                        "internalType": "uint128",
                        "name": "attack",
                        "type": "uint128"
                      },
                      {
                        "internalType": "uint32",
                        "name": "quality",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "locked",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "edition",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "kind",
                        "type": "uint32"
                      },
                      {
                        "internalType": "bool",
                        "name": "revealed",
                        "type": "bool"
                      }
                    ],
                    "internalType": "struct IEquipment.Stats",
                    "name": "stats",
                    "type": "tuple"
                  },
                  {
                    "internalType": "uint256",
                    "name": "equipmentId",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct API.EquipmentInfo[]",
                "name": "equipments",
                "type": "tuple[]"
              },
              {
                "internalType": "uint256",
                "name": "heroId",
                "type": "uint256"
              },
              {
                "internalType": "uint64[5]",
                "name": "atks",
                "type": "uint64[5]"
              }
            ],
            "internalType": "struct API.HeroInfo[]",
            "name": "heroes",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint32[5]",
                    "name": "resistanceRatio",
                    "type": "uint32[5]"
                  },
                  {
                    "internalType": "uint32",
                    "name": "moves",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "hp",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "class",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32[5]",
                    "name": "resistanceAbsolute",
                    "type": "uint32[5]"
                  },
                  {
                    "internalType": "uint32",
                    "name": "vitCost",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "invulnerable",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint256",
                    "name": "board",
                    "type": "uint256"
                  },
                  {
                    "internalType": "address",
                    "name": "reward",
                    "type": "address"
                  }
                ],
                "internalType": "struct ILevel.Info",
                "name": "info",
                "type": "tuple"
              },
              {
                "internalType": "uint32",
                "name": "timeStart",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "timeLength",
                "type": "uint32"
              }
            ],
            "internalType": "struct ILevel.Special",
            "name": "level",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "exp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "gp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "native",
                "type": "uint256"
              },
              {
                "internalType": "bool",
                "name": "heroReward",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "equipmentReward",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "nftReward",
                "type": "bool"
              }
            ],
            "internalType": "struct API.RewardInfo",
            "name": "reward",
            "type": "tuple"
          }
        ],
        "internalType": "struct API.GameInfo",
        "name": "",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "gameId",
        "type": "uint256"
      }
    ],
    "name": "getGame",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "internalType": "address",
                "name": "player",
                "type": "address"
              },
              {
                "internalType": "uint256",
                "name": "gameId",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "levelId",
                "type": "uint256"
              },
              {
                "internalType": "uint256[3]",
                "name": "heroId",
                "type": "uint256[3]"
              },
              {
                "internalType": "uint128",
                "name": "timeStart",
                "type": "uint128"
              },
              {
                "internalType": "uint128",
                "name": "timeEnd",
                "type": "uint128"
              },
              {
                "internalType": "uint256",
                "name": "nonce",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "board",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "damageMade",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "moveMade",
                "type": "uint256"
              },
              {
                "internalType": "bool",
                "name": "specialLevel",
                "type": "bool"
              }
            ],
            "internalType": "struct ICryptoRumble.Game",
            "name": "game",
            "type": "tuple"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint128",
                    "name": "attack",
                    "type": "uint128"
                  },
                  {
                    "internalType": "uint32",
                    "name": "quality",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "class",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "lv",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "exp",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "locked",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "lvCap",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "edition",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint128",
                    "name": "atkGroth",
                    "type": "uint128"
                  },
                  {
                    "internalType": "uint128[3]",
                    "name": "equiped",
                    "type": "uint128[3]"
                  },
                  {
                    "internalType": "bool",
                    "name": "revealed",
                    "type": "bool"
                  }
                ],
                "internalType": "struct IHero.Stats",
                "name": "stats",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "components": [
                      {
                        "internalType": "uint128",
                        "name": "attack",
                        "type": "uint128"
                      },
                      {
                        "internalType": "uint32",
                        "name": "quality",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "locked",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "edition",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "kind",
                        "type": "uint32"
                      },
                      {
                        "internalType": "bool",
                        "name": "revealed",
                        "type": "bool"
                      }
                    ],
                    "internalType": "struct IEquipment.Stats",
                    "name": "stats",
                    "type": "tuple"
                  },
                  {
                    "internalType": "uint256",
                    "name": "equipmentId",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct API.EquipmentInfo[]",
                "name": "equipments",
                "type": "tuple[]"
              },
              {
                "internalType": "uint256",
                "name": "heroId",
                "type": "uint256"
              },
              {
                "internalType": "uint64[5]",
                "name": "atks",
                "type": "uint64[5]"
              }
            ],
            "internalType": "struct API.HeroInfo[]",
            "name": "heroes",
            "type": "tuple[]"
          },
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint32[5]",
                    "name": "resistanceRatio",
                    "type": "uint32[5]"
                  },
                  {
                    "internalType": "uint32",
                    "name": "moves",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "hp",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "class",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32[5]",
                    "name": "resistanceAbsolute",
                    "type": "uint32[5]"
                  },
                  {
                    "internalType": "uint32",
                    "name": "vitCost",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "invulnerable",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint256",
                    "name": "board",
                    "type": "uint256"
                  },
                  {
                    "internalType": "address",
                    "name": "reward",
                    "type": "address"
                  }
                ],
                "internalType": "struct ILevel.Info",
                "name": "info",
                "type": "tuple"
              },
              {
                "internalType": "uint32",
                "name": "timeStart",
                "type": "uint32"
              },
              {
                "internalType": "uint32",
                "name": "timeLength",
                "type": "uint32"
              }
            ],
            "internalType": "struct ILevel.Special",
            "name": "level",
            "type": "tuple"
          },
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "exp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "gp",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "native",
                "type": "uint256"
              },
              {
                "internalType": "bool",
                "name": "heroReward",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "equipmentReward",
                "type": "bool"
              },
              {
                "internalType": "bool",
                "name": "nftReward",
                "type": "bool"
              }
            ],
            "internalType": "struct API.RewardInfo",
            "name": "reward",
            "type": "tuple"
          }
        ],
        "internalType": "struct API.GameInfo",
        "name": "gameInfo",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "from",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "length",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.Filter",
        "name": "filter",
        "type": "tuple"
      }
    ],
    "name": "getOwnedEquipments",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint128",
                    "name": "attack",
                    "type": "uint128"
                  },
                  {
                    "internalType": "uint32",
                    "name": "quality",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "locked",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "edition",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "kind",
                    "type": "uint32"
                  },
                  {
                    "internalType": "bool",
                    "name": "revealed",
                    "type": "bool"
                  }
                ],
                "internalType": "struct IEquipment.Stats",
                "name": "stats",
                "type": "tuple"
              },
              {
                "internalType": "uint256",
                "name": "equipmentId",
                "type": "uint256"
              }
            ],
            "internalType": "struct API.EquipmentInfo[]",
            "name": "stats",
            "type": "tuple[]"
          },
          {
            "internalType": "uint256",
            "name": "total",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.OwnedEquipment",
        "name": "equipments",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "from",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "length",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.Filter",
        "name": "filter",
        "type": "tuple"
      }
    ],
    "name": "getOwnedHeroes",
    "outputs": [
      {
        "components": [
          {
            "components": [
              {
                "components": [
                  {
                    "internalType": "uint128",
                    "name": "attack",
                    "type": "uint128"
                  },
                  {
                    "internalType": "uint32",
                    "name": "quality",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "class",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "lv",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "exp",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "locked",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "lvCap",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint32",
                    "name": "edition",
                    "type": "uint32"
                  },
                  {
                    "internalType": "uint128",
                    "name": "atkGroth",
                    "type": "uint128"
                  },
                  {
                    "internalType": "uint128[3]",
                    "name": "equiped",
                    "type": "uint128[3]"
                  },
                  {
                    "internalType": "bool",
                    "name": "revealed",
                    "type": "bool"
                  }
                ],
                "internalType": "struct IHero.Stats",
                "name": "stats",
                "type": "tuple"
              },
              {
                "components": [
                  {
                    "components": [
                      {
                        "internalType": "uint128",
                        "name": "attack",
                        "type": "uint128"
                      },
                      {
                        "internalType": "uint32",
                        "name": "quality",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "locked",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "edition",
                        "type": "uint32"
                      },
                      {
                        "internalType": "uint32",
                        "name": "kind",
                        "type": "uint32"
                      },
                      {
                        "internalType": "bool",
                        "name": "revealed",
                        "type": "bool"
                      }
                    ],
                    "internalType": "struct IEquipment.Stats",
                    "name": "stats",
                    "type": "tuple"
                  },
                  {
                    "internalType": "uint256",
                    "name": "equipmentId",
                    "type": "uint256"
                  }
                ],
                "internalType": "struct API.EquipmentInfo[]",
                "name": "equipments",
                "type": "tuple[]"
              },
              {
                "internalType": "uint256",
                "name": "heroId",
                "type": "uint256"
              },
              {
                "internalType": "uint64[5]",
                "name": "atks",
                "type": "uint64[5]"
              }
            ],
            "internalType": "struct API.HeroInfo[]",
            "name": "stats",
            "type": "tuple[]"
          },
          {
            "internalType": "uint256",
            "name": "total",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.OwnedHero",
        "name": "heroes",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract IERC721Enumerable",
        "name": "nft",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "player",
        "type": "address"
      },
      {
        "components": [
          {
            "internalType": "uint256",
            "name": "from",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "length",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.Filter",
        "name": "filter",
        "type": "tuple"
      }
    ],
    "name": "getOwnedNFT",
    "outputs": [
      {
        "components": [
          {
            "internalType": "uint256[]",
            "name": "ids",
            "type": "uint256[]"
          },
          {
            "internalType": "uint256",
            "name": "total",
            "type": "uint256"
          }
        ],
        "internalType": "struct API.OwnedNFT",
        "name": "owned",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      }
    ],
    "name": "getRoleAdmin",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "grantRole",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "hasRole",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "hero",
    "outputs": [
      {
        "internalType": "contract Hero",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "heroEdition",
    "outputs": [
      {
        "internalType": "contract HeroEdition",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract CryptoRumble",
        "name": "cr",
        "type": "address"
      },
      {
        "internalType": "contract Equipment",
        "name": "eq",
        "type": "address"
      },
      {
        "internalType": "contract Hero",
        "name": "h",
        "type": "address"
      },
      {
        "internalType": "contract Level",
        "name": "l",
        "type": "address"
      },
      {
        "internalType": "contract EquipmentEdition",
        "name": "ee",
        "type": "address"
      },
      {
        "internalType": "contract HeroEdition",
        "name": "he",
        "type": "address"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "level",
    "outputs": [
      {
        "internalType": "contract Level",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "proxiableUUID",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "callerConfirmation",
        "type": "address"
      }
    ],
    "name": "renounceRole",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "role",
        "type": "bytes32"
      },
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "revokeRole",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract CheckIn",
        "name": "ci",
        "type": "address"
      }
    ],
    "name": "setCheckIn",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract Equipment",
        "name": "eq",
        "type": "address"
      },
      {
        "internalType": "contract EquipmentEdition",
        "name": "ed",
        "type": "address"
      }
    ],
    "name": "setEquipment",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "contract Level",
        "name": "l",
        "type": "address"
      }
    ],
    "name": "setLevel",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes4",
        "name": "interfaceId",
        "type": "bytes4"
      }
    ],
    "name": "supportsInterface",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newImplementation",
        "type": "address"
      },
      {
        "internalType": "bytes",
        "name": "data",
        "type": "bytes"
      }
    ],
    "name": "upgradeToAndCall",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  }
] as const;