import { linea } from "viem/chains";

export default {
  [`${linea.id}`]: {
    Hero: {
      "address": ""
    },
    HeroEdition2: {
      "address": "0xb0D2D285F9beBFA151CF9e6dCaD44FE3C7052D44"
    },
    HeroPresale: {
      "address": "0x605412906f553B8d305FbD9723f9dAF832811c62"
    },
    Restaking: "0xb78e82CF9457C3Cf6b275E8387038764baDa6F73",
    WETH: "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
    wstETH: "0xB5beDd42000b71FddE22D3eE8a79Bd49A568fC8F",
    ezETH: "0x2416092f143378750bb29b79eD961ab195CcEea5",
    STONE: "0x93F4d0ab6a8B4271f4a28Db399b5E30612D21116",
    weETH: "0x1Bf74C010E6320bab11e2e5A532b5AC15e0b8aA6",
  },
} as const;
